;(function($){

$.fn.infinite = function(setting) {
  var _d = {
    url: '',
    bufferPx: 0,
    startAtInit: false,
    callback: function(data) {
    }
  };
  var _s = $.extend(_d, setting);
  var loadingClass = 'infinite-loading';
  var bindingClass = 'infinite-binding';

  var $container = $(this).addClass(bindingClass);
  var startLoading = function() {
    if ($container.hasClass(bindingClass) && ! $container.hasClass(loadingClass)) {
      var $loading = $container.addClass(loadingClass)
        .css('position', 'relative')
        .append('<span style="display: none;" id="infiniteLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>')
        .find('#infiniteLoading');
        $loading.css({
          position: 'absolute',
          bottom: 0,
          left: $container.width() / 2 - $loading.outerWidth(true) / 2,
          'z-index': 9999
        }).fadeIn(300);
      if (_s.url != '') {
        $.get(_s.url, function(data) {
          $loading.fadeOut(400, function() {
            $(this).remove();
          });
          _s.callback(data);
          setTimeout(function(){ 
            $container.removeClass(loadingClass);
          }, 3000);
        });
      }
    }
  }

  if (_s.startAtInit) {
    startLoading();
  }
  $(window).scroll(function(event) {
    if ($(window).scrollTop() + _s.bufferPx >= ($container.offset().top + $container.height() - $(window).height())) {
      startLoading();
    }
  });
  return {
    destroy: function() {
      $container.removeClass(bindingClass);
    },
    setUrl: function(url) {
      _s.url = url;
      return this;
    },
    setBufferPx: function(px) {
      _s.bufferPx = px;
      return this;
    }
  };
};

})(jQuery);