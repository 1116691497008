/**
 * PauleanRWall
 */
(function ($, document, window) {
    function PauleanWall($dom, options) {
        this.settings = $.extend({
            childClass: '.photowall-box',
            hasRenderClass: 'pauleanr-photowall-box',
            padding: 5,
            limit: {
                lg: 6,
                md: 4,
                sm: 3,
                xs: 1
            }
        }, options);

        this.element = $dom;
        this.lastOuterWidth = $dom.width();

        this.notFillable = [];
        this.fillable = [];

        this.init();
    }

    function generateNewBox() {
        var array = {
            fill: 0,
            size: {
                1: [],
                2: [],
                3: []
            }
        };
        return array;
    }

    PauleanWall.prototype.init = function() {
        this.element.addClass('photowall-container').data('width', this.lastOuterWidth);
        this.reload();
    }

    PauleanWall.prototype.resize = function() {
        var outerWidth = this.element.width();
        if (outerWidth != this.lastOuterWidth) {
            this.notFillable = [];
            this.fillable = [];
            this.lastOuterWidth = outerWidth;
            this.setPhotoSize(this.element.find(this.settings.childClass).removeClass(this.settings.hasRenderClass));
        }
    }

    PauleanWall.prototype.reload = function() {
        var $notResizeElement = this.element.find(this.settings.childClass).not('.' + this.settings.hasRenderClass);
        this.setPhotoSize($notResizeElement);
    }

    PauleanWall.prototype.setPhotoSize = function($elements) {
        var self = this;
        var outerWidth = this.element.width();
        var boxPerLine = 1;
        if (outerWidth >= 1440) {
            boxPerLine = 4;
        } else if (outerWidth >= 900) {
            boxPerLine = 3;
        } else if (outerWidth >= 600) {
            boxPerLine = 2;
        }
        var numPerLine = boxPerLine * 2;
        if ($elements.length > 0) {
            var size = (outerWidth - (self.settings.padding * numPerLine * 2)) / numPerLine;
            var doubleSize = size * 2 + self.settings.padding * 2;

            $elements.each(function(index, el) {
                var weight = $(this).data('weight');
                var $item = $(this).addClass(self.settings.hasRenderClass).css('margin', self.settings.padding).css('opacity', 1);

                if (weight >= 2) {
                    var box = generateNewBox();
                    var index = self.fillable.length;
                    box.fill = 4;
                    box.size[3].push($item);
                    self.fillable[index] = box;
                } else {
                    if (self.notFillable.length == 0) {
                        var index = self.fillable.length;
                        self.fillable[index] = generateNewBox();
                        self.notFillable.push(index);
                    }
                    $.each(self.notFillable, function(key, index) {
                        if (self.fillable[index].fill + weight <= 4) {
                            self.fillable[index].fill += weight;
                            self.fillable[index].size[weight].push($item);
                            if (self.fillable[index].fill == 4) {
                                self.notFillable.splice(key, 1);
                            }
                            return false;
                        }
                    });
                }
            });
            $.each(self.fillable, function(index, box) {
                var x = (index % boxPerLine) * (doubleSize + self.settings.padding * 2) + self.settings.padding;
                var y = Math.floor(index / boxPerLine) * (doubleSize + self.settings.padding * 2) + self.settings.padding;
                if (box.size[3].length > 0) {
                    box.size[3][0].css('left', x).css('top', y).width(doubleSize).height(doubleSize);
                } else if (box.size[2].length > 1) {
                    $.each(box.size[2], function(num, $img) {
                        var xDiff = (num % 2) * size;
                        var yDiff = num < 1 ? 0 : size;
                        $img.css('left', x + xDiff).css('top', y + yDiff).width(size).height(doubleSize);
                    });
                } else if (box.size[2].length > 0) {
                    box.size[2][0].css('left', x).css('top', y).width(size).height(doubleSize);
                    $.each(box.size[1], function(num, $img) {
                        var yDiff = num < 1 ? 0 : size;
                        $img.css('left', x + size).css('top', y + yDiff).width(size).height(size);
                    });
                } else {
                    $.each(box.size[1], function(num, $img) {
                        var xDiff = (num % 2) * (size + self.settings.padding * 2);
                        var yDiff = num < 2 ? 0 : (size + self.settings.padding * 2);
                        $img.css('left', x + xDiff).css('top', y + yDiff).width(size).height(size);
                    });
                }
            });
            this.element.height(Math.ceil(self.fillable.length / boxPerLine) * (doubleSize + self.settings.padding * 2));
        }
    }

    $.fn.mosaicwall = function(options) {
        var photowall = new PauleanWall(this, options);
        $(window).resize(function(event) {
            photowall.resize();
        });
        return photowall;
    }
}(jQuery, document, window));