/**
 * PauleanRWall
 */
(function ($, document, window) {
    var fitOuterWidth = function(targetElement, outerWidth, padding, addtionHeight) {
        var realOuterWidth = outerWidth - (targetElement.length * 2 * padding) - 1;
        var nowTotalWidth = 0;
        $.each(targetElement, function(index, element) {
            nowTotalWidth += $(element).width();
        });

        var scale = realOuterWidth / nowTotalWidth;
        $.each(targetElement, function(index, element) {
            var $element = $(element);
            var $image = $element.find('.card-action');
            var width = Math.floor($image.data('width') * scale);
            var height = Math.floor($image.data('height') * scale);
            $element.width(width).height(height + addtionHeight)
            $image.height(height).width(width);
        });
    }

    function PauleanWall($dom, options) {
        this.settings = $.extend({
            childClass: '.photowall-box',
            hasRenderClass: 'pauleanr-photowall-box',
            height: 300,
            padding: 5,
            showDetail: false
        }, options);

        this.element = $dom;
        this.lastOuterWidth = $dom.width();
        this.targetElement = [];
        this.targetLineWidth = 0;
        this.addtionHeight = null;
        this.init();
    }

    PauleanWall.prototype.init = function() {
        this.element.addClass('photowall-container').data('width', this.lastOuterWidth);
        this.reload();
    }

    PauleanWall.prototype.resize = function() {
        var outerWidth = this.element.width();
        if (outerWidth != this.lastOuterWidth) {
            this.lastOuterWidth = outerWidth;
            this.targetElement = [];
            this.targetLineWidth = 0;
            this.setPhotoSize(this.element.find(this.settings.childClass));
        }
    }

    PauleanWall.prototype.reload = function() {
        var $notResizeElement = this.element.find(this.settings.childClass);

        this.targetElement = [];
        this.targetLineWidth = 0;
        this.setPhotoSize($notResizeElement);
    }

    PauleanWall.prototype.setPhotoSize = function($elements) {
        var self = this;
        if ($elements.length > 0) {
            var lessAmountPerLine = 0;
            if (self.lastOuterWidth > 1000) {
                lessAmountPerLine = 2;
            } else if (self.lastOuterWidth > 500) {
                lessAmountPerLine = 1;
            }
            $elements.each(function(index, el) {
                var originW = $(this).data('width');
                var originH = $(this).data('height');
                var newH = self.settings.height;
                var newW = self.settings.height / originH * originW;
                // count addtion height
                if (self.settings.showDetail == true) {
                    $(this).addClass('photowall-box--show-detail');
                    if (self.addtionHeight === null) {
                        self.addtionHeight = $(this).find('.card-detail').outerHeight(true);
                    }
                }
                $(this).width(newW).height(newH + self.addtionHeight).css('margin', self.settings.padding).css('opacity', 1)
                    .find('.card-action').height(newH).width(newW).data('height', newH).data('width', newW);
                $(this).addClass(self.settings.hasRenderClass);
                // 不夠塞了
                if ((newW / 3 + self.settings.padding * 2 + self.targetLineWidth) > self.lastOuterWidth && self.targetElement.length > lessAmountPerLine) {
                    fitOuterWidth(self.targetElement, self.lastOuterWidth, self.settings.padding, self.addtionHeight);

                    self.targetElement = [];
                    self.targetLineWidth = 0;
                }

                self.targetElement.push(this);
                self.targetLineWidth += (newW + self.settings.padding * 2);
            });

            if (self.targetLineWidth > self.lastOuterWidth) {
                fitOuterWidth(self.targetElement, self.lastOuterWidth, self.settings.padding, self.addtionHeight);
            }
        }
    }

    $.fn.photowall = function(options) {
        var photowall = new PauleanWall(this, options);
        $(window).resize(function(event) {
            photowall.resize();
        });
        setTimeout(function() {
            photowall.resize();
        }, 1000);
        return photowall;
    }
}(jQuery, document, window));